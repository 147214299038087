<script setup lang="ts">
import {ref, computed} from 'vue';
import {useTimestamp} from '@vueuse/core';
import { VueFinalModal } from 'vue-final-modal';
import {useAsyncData} from '#app';
import {formatDeadline as _formatDeadline} from "~/utils/date.ts";
import {getOffers} from '~/api/tg-bot.js';
import type {GemBotOffersResponse} from '~/api/tg-bot-models.js';
import {useMainStore} from "~/store/index.js";
import BaseLoader from '~/components/base/BaseLoader.vue';
import Notification from '~/components/base/Notification.vue';
import {toast} from "vue-sonner";


const props = defineProps({
    label: {
        type: String,
        required: false,
    },
    giveaway: {
        type: String,
        default: '',
    },
});

const now = useTimestamp();
const isNotificationOpened = ref(false);
const isModalVisible = ref(false);

const store = useMainStore();

const {data: offer} = useAsyncData(async () => {
    const offerList = await getOffers();
    const offer = findOffer(offerList);
    isNotificationOpened.value = !!offer && !props.giveaway;
    return offer;
});

function findOffer(offerList: Array<GemBotOffersResponse>) {
    return offerList[0];
}

function formatDeadline(deadline?: Date | string | number) {
    return _formatDeadline(deadline, now.value);
}

const shouldShowOffer = computed(() => offer && !props.giveaway);

const shareUrl = computed(() => {
    const start = props.giveaway ? `giveaway-${props.giveaway}-${store.tgUser?.id}` : store.tgUser?.id;
    return `https://t.me/gembot?start=${start}`;
});
const shareText = computed(() => 'It\'s the GEM!');
const tgUrl = computed(() => `https://t.me/share/url?url=${encodeURIComponent(shareUrl.value)}&text=${encodeURIComponent(shareText.value)}`);
const whatsAppUrl = computed(() => `https://api.whatsapp.com/send?text=${encodeURIComponent(shareText.value)}%20${encodeURIComponent(shareUrl.value)}`);
const twitterUrl = computed(() => `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl.value)}&text=${encodeURIComponent(shareText.value)}`);
const isShareSupported = !!window.navigator.share;

function copyUrl() {
    toast('Copied', {
        position: 'top-center',
        duration: 3000,
        classes: {
            toast: 'toast--copy',
        },
    });
    return window.navigator.clipboard.writeText(shareUrl.value);
}

function nativeShare() {
    window.navigator.share({
        // title: undefined,
        text: shareText.value,
        url: shareUrl.value,
    })
        .catch((error) => {
            // share cancelled
            // console.error('Error sharing', error);
        });
}

defineExpose({
    open() {
        isModalVisible.value = true;
    },
});
</script>

<template>
    <button type="button" v-bind="$attrs" @click="isModalVisible = true" v-haptic-feedback="'medium'">
        {{ props.label || 'Earn with friends' }}
    </button>

    <Notification
        v-model:is-open="isNotificationOpened"
        title="Get +1,000,000 XP"
        :description="`Invite a friend within ${ formatDeadline(offer?.deadlineAt)} to&nbsp;get XP`"
        @click="isModalVisible = true"
        v-haptic-feedback="'medium'"
    >
        <template v-slot:left>
            <img class="notification__image panel--light-blue" src="/img/notification-message.svg" width="58" height="58" alt="" role="presentation">
        </template>
        <template v-slot:right>
            <button type="button" class="button button--main button--small" @click="isModalVisible = true" v-haptic-feedback="'medium'">Invite</button>
        </template>
    </Notification>

    <VueFinalModal
        class="modal modal-theme-bottom u-text-center"
        content-class="modal__content"
        v-model="isModalVisible"
        overlay-transition="vfm-fade"
        content-transition="vfm-slide-down"
        swipe-to-close="down"
    >
        <div v-if="offer && shouldShowOffer" class="modal__panel panel--light panel--light-blue">
            <img class="u-image u-mb-05" src="/img/notification-message.svg" width="100" height="100" alt="" role="presentation">
            <h2 class="u-h2 u-mb-10">Invite a friend within {{ formatDeadline(offer.deadlineAt) }}</h2>
            <p class="u-fw-400">
                Invite just 1 friend within the specified time and get 100,000 XP. If you invite a friend with Telegram Premium status, you will get 1,000,000 XP. Hurry!
            </p>
        </div>
        <div class="modal__panel panel--light">
            <h2 v-if="!shouldShowOffer" class="u-h2 u-mb-15">Invite your friends</h2>

            <div class="u-flex u-flex--justify-between">
                <a :href="tgUrl" class="share__social u-semantic-button link--opacity" target="_blank" v-haptic-feedback>
                    <img class="share__social-icon" src="/img/icon-share-tg.svg" alt="" width="48" height="48">
                    Telegram
                </a>
                <a :href="whatsAppUrl" class="share__social u-semantic-button link--opacity" target="_blank" v-haptic-feedback>
                    <img class="share__social-icon" src="/img/icon-share-whatsapp.svg" alt="" width="48" height="48">
                    WhatsApp
                </a>
                <a :href="twitterUrl" class="share__social u-semantic-button link--opacity" target="_blank" v-haptic-feedback>
                    <img class="share__social-icon" src="/img/icon-share-x.svg" alt="" width="48" height="48">
                    X.com
                </a>
                <button type="button" class="share__social u-semantic-button link--opacity" @click="copyUrl()" v-haptic-feedback>
                    <img class="share__social-icon" src="/img/icon-share-link.svg" alt="" width="48" height="48">
                    Copy link
                </button>
                <button v-if="isShareSupported" type="button" class="share__social u-semantic-button link--opacity" @click="nativeShare()" v-haptic-feedback >
                    <img class="share__social-icon" src="/img/icon-share-native.svg" alt="" width="48" height="48">
                    Share via…
                </button>
            </div>

            <button
                type="button"
                class="button button--ghost-main button--full button--large u-mt-15 u-mb-10"
                :class="{'is-loading': false}"
                @click="isModalVisible = false"
                v-haptic-feedback="'medium'"
            >
                <BaseLoader class="button__loader" :is-loading="true"/>
                <span class="button__content">Cancel</span>
            </button>
        </div>
    </VueFinalModal>
</template>

<style scoped>
.share__header {font-size: 24px;line-height: 24px;font-weight: 600;}
.share__social {min-width: 54px; font-size: 12px; line-height: 1; text-align: center; font-weight: 400;}
.share__social-icon {display: block; margin-bottom: 8px; margin-inline: auto;}

</style>
