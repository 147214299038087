import {parseISO, format} from "date-fns";

export function formatDeadline(deadline: Date|string|number|undefined, now = Date.now()) {
    if (!deadline) {
        return '';
    }
    deadline = new Date(deadline).getTime();
    const timeToNextGame = deadline > now ? deadline - now : 0;
    const seconds = timeToNextGame / 1000 % 60;
    const minutes = timeToNextGame / (60 * 1000) % 60;
    const hours = timeToNextGame / (60 * 60 * 1000);
    return `${padAndRound(hours)}:${padAndRound(minutes)}:${padAndRound(seconds)}`;
}

function padAndRound(value: number) {
    return Math.floor(value).toString().padStart(2, '0');
}

type Timestamp = string | number | Date;

function parseTime(timestamp: Timestamp) {
    if (timestamp instanceof Date) {
        return timestamp;
    }
    if (typeof timestamp === 'string') {
        return parseISO(timestamp);
    }
    if (typeof timestamp === 'number') {
        return new Date(timestamp);
    }

    return null;
}

export function getDateLong(timestamp: Timestamp) {
    const timestampParsed = parseTime(timestamp);
    if (!timestampParsed) {
        return false;
    }

    return format(timestampParsed, 'dd MMMM yyyy');
}

export function getTime(timestamp: Timestamp, isShort = false) {
    const timestampParsed = parseTime(timestamp);
    if (!timestampParsed) {
        return false;
    }

    const secondsPart = isShort ? '' : ':ss';

    return format(timestampParsed, `HH:mm${secondsPart}`);
}
