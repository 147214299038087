<script setup lang="ts">
import {ref, computed, watch} from 'vue';

const emit = defineEmits<{
    click: [event: MouseEvent],
}>();

const props = withDefaults(defineProps<{
    title: string,
    description: string,
    image?: string,
    themeClass?: string,
}>(), {
    themeClass: 'panel--dark-blue',
});

const isOpen = defineModel<boolean>('isOpen');

function handleClick(event: MouseEvent) {
    const isCloseClicked = event.target instanceof Element && event.target.closest('[data-notification-close]');
    if (!isCloseClicked) {
        emit('click', event);
    }

}

</script>

<template>
    <Transition name="vfm-slide-down" appear>
        <div class="notification-wrap" v-if="isOpen" @click="handleClick($event)">
            <div class="notification panel u-flex u-flex--align-center" :class="props.themeClass">
                <slot name="left"></slot>
                <div class="notification__content">
                    <h2 class="u-h3">{{ props.title }}</h2>
                    <p>{{ props.description }}</p>
                </div>
                <slot name="right"></slot>

                <button type="button" class="notification__close link--opacity u-semantic-button" @click="isOpen = false" v-haptic-feedback data-notification-close>
                    <img class="u-image" src="/img/icon-close.svg" alt="">
                </button>
            </div>
        </div>
    </Transition>
</template>

<style scoped>

</style>
